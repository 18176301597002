@import url("https://cdn.jsdelivr.net/npm/reset-css@5.0.2/reset.min.css");

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #0FFF50;
}

:root {
    --color-neon-green: #0FFF50;
    --tablet-screen-width: 768px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

::selection {
    color: black;
    background-color: var(--color-neon-green);
}

html {
    font-family: Monaco, monospace;
    font-size: 16px;
    background-color: black;
    color: var(--color-neon-green);
}

code {
    line-height: initial;
}

li:not(:last-child) {
    margin-bottom: 5px;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

a[target='_blank'] {
    cursor: alias;
}

a:focus,
a:hover,
a:active {
    outline: 0;
    color: black;
    background-color: var(--color-neon-green);
    text-decoration: none!important;
}

.terminal,
.cowsay {
    margin: 1em;
    max-width: var(--tablet-screen-width);
}

.section > * {
    margin-bottom: 1em;
}

.command {
    margin-left: 5px;
    margin-right: 5px;
}

.blinker {
    opacity: 1;
    margin-bottom: -2px;
    height: 15px;
    margin-left: -5px;
    border-left: 7px solid #0FFF50;
}

.blinker.blinking {
    animation: blinking 0.9s steps(2, start) infinite;
}

@keyframes blinking {
    to {
        visibility: hidden;
    }
}

.about {
    display: flex;
    flex-direction: row;
}

.avatar {
    width: 100px;
    height: 100px;
}

@media (width <= 250px) {
    ul.about-item {
        width: 100%;
    }
}

@media (250px < width <= 325px) {
    ul.about-item {
        width: 80%;
    }
}

@media (325px < width <= 465px) {
    ul.about-item {
        width: 60%;
    }
}

@media (465px < width <= 540px) {
    ul.about-item {
        width: 40%;
    }
}

@media (width <= 425px) {
    .ref {
        display: none;
    }
}

@media (width <= 500px) {
    .sha {
        display: none;
    }
}

@media (width <= 540px) {
    .about {
        flex-direction: column;
    }

    img.about-item {
        margin-bottom: 10px;
        align-self: center;
    }

    .avatar {
        width: 128px;
        height: 128px;
    }

    ul.about-item {
        align-self: center;
    }

    ul.about-item:not(:last-child) {
        margin-bottom: 5px;
    }
}

.visually-hidden {
    height: 1px;
    overflow: hidden;
    width: 1px;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    white-space: nowrap;
}
